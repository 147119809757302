<template>
  <v-container class="content">
    <title-header-action
      :title="title"
      :btnAction="goToAddRecycler"
      :hasActionPermission="canRegisterNewRecycler"
      btnLabel="Cadastrar Reciclador"
    />
    <v-card class="pt-5">
      <div class="table-page d-flex flex-column justify-space-between">
        <div>
          <template v-if="showFilters">
            <div class="row px-5 d-flex justify-end">
              <v-col cols="12" md="3">
                <v-select
                  :items="states"
                  item-text="name"
                  item-value="id"
                  outlined
                  @change="setFilter('uf', $event)"
                  color="secondary-color-1"
                  label="Todas UF"
                ></v-select>
              </v-col>
              <v-col cols="9" sm="8" md="3">
                <v-text-field
                  @input="setFilterDebounced('residue', $event)"
                  solo
                  flat
                  full-width
                  color="secondary-color-1"
                  background-color="white-1"
                  placeholder="Buscar por Resíduo"
                  prepend-inner-icon="mdi-magnify"
                ></v-text-field>
              </v-col>
              <v-col cols="9" sm="8" md="3">
                <v-text-field
                  @input="setFilterDebounced('name', $event)"
                  solo
                  flat
                  full-width
                  color="secondary-color-1"
                  background-color="white-1"
                  placeholder="Buscar Reciclador"
                  prepend-inner-icon="mdi-magnify"
                ></v-text-field>
              </v-col>
            </div>
            <v-divider></v-divider>
          </template>
          <template v-if="recyclersData.length">
            <v-data-table
              :headers="headers"
              :items="recyclersData"
              :items-per-page="tableInfo.itemsPerPage"
              hide-default-footer
            >
              <template v-slot:[`item.status`]="{ item }">
                <recycler-status-chip :status="item.status.id" />
              </template>

              <template v-slot:[`item.situation`]="{ item }">
                <recycler-situation-chip :situation="item.situation.id" />
              </template>

              <template v-slot:[`item.id`]="{ item }">
                <recyclers-menu-item
                  :recycler="item"
                  @update="updateData"
                  @delete="handleDeleteRecycler"
                />
              </template>
            </v-data-table>
          </template>

          <template v-if="!recyclersData.length">
            <div class="pt-10">
              <data-not-found
                v-if="!filterPristine"
                title="Reciclador não encontrado."
                :imgSrc="require('@/assets/recycler.svg')"
              />
              <data-not-found
                v-if="filterPristine"
                title="Você ainda não cadastrou nenhum reciclador."
                btnLabel="Cadastrar o primeiro Reciclador"
                :btnAction="goToAddRecycler"
                :imgSrc="require('@/assets/recycler.svg')"
              />
            </div>
          </template>
        </div>
        <div v-if="recyclersData.length">
          <pagination
            :currentPage="tableInfo.currentPage"
            :totalPages="tableInfo.totalPages"
            :itemsPerPage="tableInfo.itemsPerPage"
            @currentPageChanged="handleCurrentPageChange($event)"
            @itemsPerPageChanged="handleItemsPerPageChange($event)"
          />
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
  import MasksMixin from "@/modules/core/mixins/masksMixin";
  import TitleHeaderAction from "@/modules/core/views/components/TitleHeaderAction";
  import TableHandler from "@/modules/core/mixins/tableHandler";
  import Pagination from "@/modules/core/views/components/Pagination";
  import DataNotFound from "@/modules/core/views/components/DataNotFound";
  import RecyclersMenuItem from "@/modules/recycler/views/components/RecyclersMenuItem";
  import RecyclerStatusChip from "@/modules/recycler/views/components/RecyclerStatusChip";
  import RecyclerSituationChip from "@/modules/recycler/views/components/RecyclerSituationChip";

  import { recyclerStatus } from "@/modules/recycler/enums/recyclerStatus.enum";
  import { recyclerSituations } from "@/modules/recycler/enums/recyclerSituations.enum";

  import { mapActions, mapGetters } from "vuex";
  import { states } from "@/modules/core/enums/states.enum";

  export default {
    name: "Recyclers",

    components: {
      TitleHeaderAction,
      DataNotFound,
      Pagination,
      RecyclersMenuItem,
      RecyclerStatusChip,
      RecyclerSituationChip
    },

    mixins: [MasksMixin, TableHandler],

    created() {
      this.initFilter("name", undefined);
      this.initFilter("residue", undefined);
      this.initFilter("uf", undefined);
    },

    data: () => ({
      title: "Recicladores",
      headers: [
        {
          text: "Nome da empresa",
          align: "start",
          value: "name"
        },
        { text: "Representante", value: "recyclerAgent" },
        { text: "Telefone", value: "phones" },
        { text: "E-mail", value: "email" },
        { text: "Status", value: "status" },
        { text: "Situação", value: "situation" },
        { value: "id", sortable: false }
      ],
      headerReport: [
        {
          header: "Nome da empresa",
          dataKey: "name"
        },
        { header: "Representante", dataKey: "recyclerAgent" },
        { header: "Telefone", dataKey: "phones" },
        { header: "E-mail", dataKey: "email" },
        { header: "Status", dataKey: "status" },
        { header: "Situação", dataKey: "situation" }
      ],

      recyclersData: [],

      states: []
    }),

    async mounted() {
      await this.fetchRecyclers();
      this.loadStates();
    },

    computed: {
      ...mapGetters({
        userRoleId: "user/getUserRoleId",
        routes: "getRoutes"
      }),

      showFilters() {
        if (this.recyclersData.length) {
          return true;
        }
        return !this.recyclersData.length && !this.filterPristine;
      },

      canRegisterNewRecycler() {
        return this.routes?.some(
          route =>
            route.name === "addRecycler" &&
            route?.meta?.permissions.includes(this.userRoleId)
        );
      }
    },

    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("recycler", [
        "getRecyclers",
        "deleteRecycler",
        "toggleRecyclerStatus"
      ]),

      goToAddRecycler() {
        this.$router.push({ name: "addRecycler" });
      },

      async fetchRecyclers() {
        try {
          const payload = this.getPayloadData();
          const { data, meta } = await this.getRecyclers(payload);
          this.recyclersData = this.formatRecyclersData(data);
          this.setTableInfo(meta);
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      formatRecyclersData(recyclers) {
        return recyclers.map(recycler => {
          const phones = recycler.phones.length
            ? this.parsePhone(recycler.phones[0])
            : "não informado";

          return {
            ...recycler,
            phones,
            status: this.getRecyclerStatus(recycler),
            situation: this.getRecyclerSituation(recycler)
          };
        });
      },

      getRecyclerStatus(recycler) {
        return recycler.inactivatedAt
          ? recyclerStatus.INACTIVE
          : recyclerStatus.ACTIVE;
      },

      getRecyclerSituation(recycler) {
        return recycler.isRegular
          ? recyclerSituations.REGULAR
          : recyclerSituations.IRREGULAR;
      },

      updateData() {
        this.fetchRecyclers();
      },

      loadStates() {
        this.states = Object.values([{ name: "Todos", id: "" }, ...states]);
      },

      async handleDeleteRecycler(recyclerId) {
        try {
          await this.deleteRecycler(recyclerId);
          this.toggleSnackbar({
            text: "Reciclador excluído com sucesso",
            type: "success"
          });

          this.fetchRecyclers();
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/buttons";
  @include btn-primary();
  .content {
    min-width: 350px;
  }
</style>
