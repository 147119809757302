<template>
  <div>
    <v-dialog v-model="state.dialog" scrollable :persistent="true" width="488">
      <v-card class="modal">
        <v-card-title class="modal-title"
          >Você deseja {{ mode.action }} esse Reciclador?</v-card-title
        >
        <v-divider></v-divider>

        <v-card-text class="pa-0">
          <p class="mx-7 my-4">{{ mode.text }}</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="d-flex flex-wrap justify-space-between">
          <v-btn
            class="mt-1 cancel-button"
            :class="mode.cancelClass"
            :color="mode.cancelColor"
            :block="$isMobile"
            @click="state.dialog = false"
            >Cancelar</v-btn
          >
          <v-btn
            class="ma-0 mt-1 next-step-button"
            :class="mode.confirmClass"
            :color="mode.confirmColor"
            :block="$isMobile"
            :text="mode.confirmIsText"
            @click="handleConfirm"
            >Confirmar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { recyclerStatus } from "@/modules/recycler/enums/recyclerStatus.enum";

  export default {
    name: "ToggleRecyclerStatusDialog",

    props: {
      state: {
        type: Object,
        required: true
      },

      status: {
        type: Number,
        required: true
      },

      fnConfirm: {
        type: Function,
        required: true
      }
    },

    data: () => ({
      modes: {
        [recyclerStatus.ACTIVE.id]: {
          action: "desativar",
          text:
            "Ao desativar esse Reciclador ele ficará indisponível para seus funcionários.",
          cancelColor: "error",
          cancelClass: "white--text",
          confirmColor: "error",
          confirmIsText: true
        },
        [recyclerStatus.INACTIVE.id]: {
          action: "reativar",
          text:
            "Ao reativar esse Reciclador ele ficará disponível para seus funcionários.",
          cancelColor: "white",
          cancelClass: "black-3--text",
          confirmColor: "secondary-color-1",
          confirmIsText: false,
          confirmClass: "white--text"
        }
      }
    }),

    computed: {
      mode() {
        return this.modes[this.status];
      }
    },

    methods: {
      handleConfirm() {
        this.state.dialog = false;
        this.fnConfirm();
      }
    }
  };
</script>

<style></style>
