<template>
  <v-chip v-if="mode" :color="mode.bgColor" :text-color="mode.textColor">{{
    mode.text
  }}</v-chip>
</template>

<script>
  import { recyclerSituations } from "@/modules/recycler/enums/recyclerSituations.enum";

  export default {
    name: "RecyclerSituationChip",

    props: {
      situation: {
        type: Number,
        required: true
      }
    },

    data: () => ({
      mode: undefined,
      modes: {
        [recyclerSituations.REGULAR.id]: {
          bgColor: "situation-2",
          textColor: "white",
          text: recyclerSituations.REGULAR.description
        },
        [recyclerSituations.IRREGULAR.id]: {
          bgColor: "situation-5",
          textColor: "white",
          text: recyclerSituations.IRREGULAR.description
        }
      }
    }),

    mounted() {
      this.mode = this.modes[this.situation];
    }
  };
</script>

<style></style>
