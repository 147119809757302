<template>
  <div>
    <v-menu transition="slide-x-transition" bottom right>
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on">
          mdi-dots-horizontal
        </v-icon>
      </template>
      <v-list>
        <v-list-item
          @click="recyclerProfile"
          class="d-flex align-center item-dropdown-user"
        >
          <v-list-item-title>
            <v-icon class="item-dropdown-icon">mdi-account</v-icon
            ><span class="pl-2">Ver perfil</span>
          </v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="canManageRecycler"
          @click="prepareToggleRecyclerSituation"
          class="d-flex align-center item-dropdown-user"
        >
          <v-list-item-title>
            <v-icon class="item-dropdown-icon">{{ situationMode.icon }}</v-icon
            ><span class="pl-2">{{ situationMode.label }}</span>
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="canManageRecycler"
          @click="handleDeleteRecycler"
          class="d-flex align-center item-dropdown-user"
        >
          <v-list-item-title>
            <v-icon class="item-dropdown-icon">mdi-delete</v-icon>
            <span class="pl-2">Exluir</span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <toggle-recycler-status-dialog
      :state="toggleRecyclerStatusDialogState"
      :status="recycler.status.id"
      :fnConfirm="handleConfirmToggleSituation"
    />
  </div>
</template>

<script>
  import { mapActions } from "vuex";

  import ToggleRecyclerStatusDialog from "@/modules/recycler/views/components/ToggleRecyclerStatusDialog";
  import PermissionMixin from "@/modules/core/mixins/permissionMixin";

  import { recyclerStatus } from "@/modules/recycler/enums/recyclerStatus.enum";
  import { profiles } from "@/modules/core/enums/profiles.enum";

  export default {
    name: "RecyclersMenuItem",
    props: {
      recycler: {
        type: Object,
        required: true
      }
    },
    components: {
      ToggleRecyclerStatusDialog
    },
    mixins: [PermissionMixin],

    data: () => ({
      toggleRecyclerStatusDialogState: {
        dialog: false
      },

      situationModes: {
        [recyclerStatus.ACTIVE.id]: {
          label: "Desativar Reciclador",
          postAction: "desativado",
          icon: "mdi-block-helper"
        },
        [recyclerStatus.INACTIVE.id]: {
          label: "Reativar Reciclador",
          postAction: "reativado",
          icon: "mdi-account-reactivate"
        }
      },

      canManageRecycler: false
    }),

    mounted() {
      this.initPermissions();
    },

    computed: {
      situationMode() {
        return this.situationModes[this.recycler.status.id];
      }
    },

    methods: {
      ...mapActions("recycler", ["toggleRecyclerStatus"]),
      ...mapActions(["toggleSnackbar"]),

      initPermissions() {
        this.canManageRecycler = this.userHasProfile([
          profiles.PRESIDENT.id,
          profiles.DIRECTOR.id,
          profiles.MANAGER_OPERATING.id,
          profiles.RECEPTIONIST.id
        ]);
      },

      recyclerProfile() {
        return this.$router.push({
          name: "recyclerDetail",
          params: { id: this.recycler.id }
        });
      },

      prepareToggleRecyclerSituation() {
        this.toggleRecyclerStatusDialogState.dialog = true;
      },

      handleDeleteRecycler() {
        this.$root.$confirm
          .open(
            "Você deseja excluir esse Reciclador?",
            "Ao confirmar, a operação não poderá ser desfeita.",
            null,
            "danger"
          )
          .then(confirm => {
            if (confirm) {
              this.$emit("delete", this.recycler.id);
            }
          });
      },

      async handleConfirmToggleSituation() {
        try {
          const response = await this.toggleRecyclerStatus(this.recycler.id);

          this.toggleSnackbar({
            text:
              response?.data?.message ??
              `Reciclador ${this.situationMode.postAction} com sucesso!`,
            type: "success"
          });

          this.$emit("update");
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      }
    }
  };
</script>

<style></style>
