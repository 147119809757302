<template>
  <v-chip v-if="mode" :color="mode.bgColor" :text-color="mode.textColor">{{
    mode.text
  }}</v-chip>
</template>

<script>
  import { recyclerStatus } from "@/modules/recycler/enums/recyclerStatus.enum";

  export default {
    name: "RecyclerStatusChip",

    props: {
      status: {
        type: Number,
        required: true
      }
    },

    data: () => ({
      modes: {
        [recyclerStatus.ACTIVE.id]: {
          bgColor: "situation-2",
          textColor: "white",
          text: recyclerStatus.ACTIVE.description
        },
        [recyclerStatus.INACTIVE.id]: {
          bgColor: "situation-5",
          textColor: "white",
          text: recyclerStatus.INACTIVE.description
        }
      }
    }),

    computed: {
      mode() {
        return this.modes[this.status];
      }
    }
  };
</script>

<style></style>
